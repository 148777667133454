import {
  createContext, useContext, useEffect, ReactNode, useState, Dispatch,
} from 'react';
import { List } from 'lib/types';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';

type ProviderProps = { children: ReactNode };
type ContextProps = { lists: List[] | undefined; setLists: Dispatch<List[]> };

const ListsContext = createContext<ContextProps>({} as ContextProps);

function useUserLists() {
  return useContext(ListsContext);
}

function UserListsProvider({ children }: ProviderProps) {
  const [lists, setLists] = useState<List[] | undefined>();

  const API = useApi();
  const currentUser = useCurrentUser();
  const value = { lists, setLists };

  useEffect(() => {
    const getLists = async () => {
      if (!currentUser) return;

      const data = await API.getUserLists();
      setLists(data.filter((l) => l.status === 'active'));
    };
    if (!lists) getLists();
  }, [currentUser]);

  return (
    <ListsContext.Provider value={value}>
      {children}
    </ListsContext.Provider>
  );
}

export { UserListsProvider, useUserLists };
