import React, { ReactNode } from 'react';
import Link from 'next/link';
import { MinimalUser } from 'lib/types/users';
import { modelToSlug } from 'lib/utils/modelSlugUtils';
import { CreatorCardClickContext } from 'lib/types/events';
import { trackClickCreatorCardEvent } from 'lib/hooks/useEventTracking';
import { useApi } from 'lib/contexts/ApplicationState';

interface Props {
  /** The user whose profile (or explore) we are linking to */
  user: MinimalUser;
  /** 
   * In some cases (collaborators), we link to a different user's profile.
   * Specify the username here to override.
   */
  username?: string;
  query?: { [key: string]: string };
  disabled?: boolean;
  children: ReactNode;
  linkToExplore?: boolean;
  /** The context used in event tracking */
  context?: CreatorCardClickContext;
}

export const ProfileLink = ({
  user,
  username,
  query,
  disabled,
  children,
  linkToExplore,
  context,
}: Props) => {
  const API = useApi();

  if (disabled) return <div>{children}</div>;
  const onClick = () => {
    trackClickCreatorCardEvent(API, user.id, context);
  };

  const profileUsername = username || user.username;

  if (linkToExplore) {
    return (
      (
        <Link
          href={{ pathname: '/explore', query: { users: modelToSlug(user, 'username') } }}
          onClick={onClick}
          passHref
        >
          {children}
        </Link>
      )
    );
  }

  const pathname = `/${profileUsername}`;

  return (
    (
      <Link href={{ pathname, query }} passHref onClick={onClick}>

        {children}

      </Link>
    )
  );
};
